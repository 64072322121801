.blockquote-item {
  border-left: none;
  background-image: url(../../../images/img9.jpg);
  background-size: cover;
  background-position: center;
  @include border-radius(4px);
  z-index: 1;
  padding: 40px;
  position: relative;
  .blockquote__text {
    line-height: 30px;
    font-weight: $font-weight-medium;
    font-style: italic;
    color: $white;
  }
  .blockquote__meta {
    font-weight: $font-weight-medium;
    margin-top: 16px;
    display: -ms-flex;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    color: $white;
    font-size: $font-size-18;
    span {
      color: rgba(255, 255, 255, 0.6);
      font-size: $font-size-15;
      margin-left: 5px;
    }
  }
  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $theme-color;
    opacity: 0.8;
    z-index: -1;
    @include border-radius(4px);
  }
}